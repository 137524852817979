.prakashans {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2.5rem;
}

.prakashan_img {
    display: flex;
}

.prakashan_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media screen and (max-width: 500px) {
    .prakashans {
      flex-direction: column;
      grid-template-columns: repeat(1, 1fr);
      align-items: center;
    }
  }
  
  @media screen and (min-width: 500px) and (max-width: 1000px) {
    .prakashans {
      flex-direction: column;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
    }
  }