.header1 {
  display: grid;
  grid-template-columns: 25% 50% 25%;
  width: 100%;
justify-content: space-around;
}
.textLogo {
  max-width: 348px;
  height: 80px;
}
.titleHeader{
display: flex;
flex-direction: column;
text-align: center;
margin: 10px;
}
.titleHeader h2{
  font-weight: bold;
color: #D8381A;
}
.titleHeader h5{
  color: #86003e;
}
.address{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 10px;

}
.address p{
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}


@media only screen and (max-width: 600px) {
  .header1 {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .textLogo {
    width: 100%;
  }
  .imgLogo {
    width: "100%";
    display: "flex";
    justify-content: "center";
    align-items: "center";
  }
}
