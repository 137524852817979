#basic-nav-dropdown {
  color: white;
}

#responsive-navbar-nav {
  /* height: 100%; */
  z-index: 3;
  padding-left: 20px;
  color: white;
  background-image: linear-gradient(180deg, #D8381A 3%, #BF0B5E 91%)
}

.container {
  padding: 0;
  margin: 0;
  justify-content: space-between;
  margin-left: 30px;
}

.navbar {
  padding: 0;
  display: grid;
  justify-content: center;
  align-items: center;
}

.nav-link {
  color: white;
}

.navItem:hover {
  background-color: #86003e;
  color: white;
}

.dropdown-menu {
  background-image: linear-gradient(180deg, #D8381A 3%, #BF0B5E 91%);
}

/* .nav-item.dropdown:hover .dropdown-menu {
  display: block;
} */

.dropdown-item {
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .navbar {
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}