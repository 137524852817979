.history {
    margin: 0 1.5rem;
}

.history_head {
    color: #1D92D6;
}

.details {
    font-size: medium;
    text-align: justify;
    font-weight: 400;
}

.mission_head {
    color: #1D92D6;
}

.content {
    padding: 3rem 10rem;
}

@media screen and (max-width : 500px) {
    .content{
        padding: 3rem 1rem;
    }
}

.intro_head {
    color: #1D92D6;
}

.subtitle {
    font-weight: 600;
    color: #A10F33;
    font-size: 1.25rem;
}

.cards {
    display: grid;
    column-gap: 2rem;
    row-gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
}

@media screen and (max-width : 500px) {
    .cards {
        grid-template-columns: repeat(1, 1fr);
    }
}

.img1 {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    border-style: solid;
    border-radius: 2rem;
    padding: 0.5rem;
}

.img1 img {
    max-width: 100%;
    height: auto;
}

.img2 {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    border-style: solid;
    border-radius: 2rem;
    padding: 0.5rem;
}

.img2 img {
    max-width: 100%;
    height: auto;
}